import { Component, inject, OnDestroy, OnInit } from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MenuItem } from 'primeng/api';

import {
    BILLING,
    CREATE_ANY,
    WAREHOUSE_MAIN,
    CustomerPermissions,
} from '@qwyk/core';
import { Portals } from '@qwyk/models';
import { SideBarService } from '@qwyk/portals/ui';
import { environment } from '@qwyk/portals/environment';
import { SiteConfigFacade } from '@qwyk/portals/siteconfig';
import { NotificationsFacade } from '@qwyk/portals/notifications';
import { AuthenticationFacade } from '@qwyk/portals/authentication';

import { OnboardingService } from '../../../../services/onboarding.service';

@Component({
    selector: 'qw-portal-index',
    templateUrl: './portal-index.component.html',
    styleUrls: ['./portal-index.component.scss'],
    providers: [ConfirmationService],
})
export class PortalIndexComponent implements OnInit, OnDestroy {

    private sideBar: SideBarService = inject(SideBarService);
    private translate: TranslateService = inject(TranslateService);
    private siteConfig: SiteConfigFacade = inject(SiteConfigFacade);
    public onboardingService: OnboardingService = inject(OnboardingService);
    private authentication: AuthenticationFacade = inject(AuthenticationFacade);
    private confirmationService: ConfirmationService = inject(ConfirmationService);
    private notificationsFacade: NotificationsFacade = inject(NotificationsFacade);

    public ENVIRONMENT = environment;
    public sidebarCollapsed = false;

    public customLinks$: Observable<{ label: string; command: () => void; }[]>;
    public user$ = this.authentication.user$;
    public features$ = this.siteConfig.features$;
    public siteDesign$ = this.siteConfig.design$;
    public sidebarStyle$ = this.sideBar.collapsed$;
    public unreadNotifications$ = this.notificationsFacade.unreadCount$;
    public isMagayaNetwork$ = this.siteConfig.isMagayaNetwork$;

    public permissions: CustomerPermissions[];
    public customerPermissionsEnum = CustomerPermissions;
    public displayMainWarehouseLink: boolean;
    public displayBilling: boolean;
    public displayCreateAny: boolean;
    public permissionsSub: Subscription;
    public addMenuItems: MenuItem[] = [];
    public design: Portals.SiteDesign;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public features: any;

    public ngOnInit() {
        this.permissionsSub = this.authentication.permissions$.pipe(
            mergeMap(source => this.siteConfig.features$.pipe(map(inner => [source, inner])))
        )
        .subscribe(
            ([permissions, features]) => {
                this.permissions = permissions;
                this.features = features;
                this.displayMainWarehouseLink = WAREHOUSE_MAIN.some(p =>
                    permissions.includes(p)
                );
                this.displayBilling = BILLING.some(p =>
                    permissions.includes(p)
                );
                this.displayCreateAny = CREATE_ANY.some(p =>
                    permissions.includes(p)
                );
                if (!features?.disable_booking_tracking?.value &&
                    this.permissions.includes(this.customerPermissionsEnum.CREATE_BOOKINGS)) {
                        this.addMenuItems.push({
                            label: this.translate.instant('portal.menu.side-menu.new-shipment'),
                            routerLink: '/my-portal/booking/new/choose',
                        });
                }
                if (permissions.includes(this.customerPermissionsEnum.CREATE_QUOTATIONS)) {
                    this.addMenuItems.push({
                        label: this.translate.instant('portal.menu.side-menu.new-quotation'),
                        routerLink: '/my-portal/booking-wizard',
                    });
                }
                if (permissions.includes(this.customerPermissionsEnum.CREATE_PURCHASEORDERS)) {
                    this.addMenuItems.push({
                        label: this.translate.instant('portal.menu.side-menu.new-purchase-order'),
                        routerLink: '/my-portal/orders/new',
                    });
                }
            }
        );

        this.customLinks$ = this.siteConfig.getCustomLinks(this.showWarningModal);
    }

    public ngOnDestroy() {
        this.permissionsSub.unsubscribe();
    }

    public toggleSidebar() {
        this.sideBar.toggle();
        this.sidebarCollapsed = !this.sidebarCollapsed;
    }

    public logout() {
        this.authentication.logout();
    }

    public resolveDefaultWarehouseRoute() {
        let defaultRoute = '';
        const warehousePermissions = [
            CustomerPermissions.VIEW_COMMODITIES,
            CustomerPermissions.VIEW_CARGORELEASES,
            CustomerPermissions.VIEW_PICKUPORDERS,
            CustomerPermissions.VIEW_INVENTORY,
            CustomerPermissions.VIEW_WAREHOUSERECEIPTS,
        ];
        warehousePermissions.every(permission => {
            if (this.permissions.includes(permission)) {
                switch (permission) {
                    case CustomerPermissions.VIEW_COMMODITIES:
                        defaultRoute = 'cargo-detail';
                        break;
                    case CustomerPermissions.VIEW_CARGORELEASES:
                        defaultRoute = 'cargo-releases';
                        break;
                    case CustomerPermissions.VIEW_PICKUPORDERS:
                        defaultRoute = 'pickup-orders';
                        break;
                    case CustomerPermissions.VIEW_INVENTORY:
                        defaultRoute = 'warehouse-inventory';
                        break;
                    case CustomerPermissions.VIEW_WAREHOUSERECEIPTS:
                        defaultRoute = 'warehouse-receipts';
                        break;
                }
                return false;
            }
            return true;
        });
        return defaultRoute;
    }

    public showWarningModal = (url?: string): void => {
        this.confirmationService.confirm({
            message: this.translate.instant('portal.leave-magaya-modal.warning'),
            accept: () => {
                window.open(url, '_blank');
            },
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            reject: () => {}
        });
    }
}
