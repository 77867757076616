import {
    AfterContentChecked,
    Component,
    inject,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { Shipment, ShipmentUI } from '@qwyk/shared-stores/shipments';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from 'primeng/button';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ShipmentSubscriptionService } from './shipment-subscription.service';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { InputSwitchModule } from 'primeng/inputswitch';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RouterLink } from '@angular/router';
import { NotificationSettingsEntity } from '@qwyk/shared-stores/notification-settings';
import { TooltipModule } from 'primeng/tooltip';
import { OverlayModule } from 'primeng/overlay';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
    selector: 'qwyk-shipment-subscription',
    standalone: true,
    imports: [
        FontAwesomeModule,
        ButtonModule,
        FormsModule,
        ReactiveFormsModule,
        ToggleButtonModule,
        InputSwitchModule,
        OverlayPanelModule,
        RouterLink,
        TooltipModule,
        OverlayModule,
        TranslateModule,
        NgIf,
    ],
    templateUrl: './shipment-subscription.component.html',
    styleUrls: ['./shipment-subscription.component.scss'],
})
export class ShipmentSubscriptionComponent
    implements OnInit, OnDestroy, AfterContentChecked
{
    @Input() public shipment: ShipmentUI | Shipment;
    @Input() public iconSize: SizeProp;
    @Input() public shipmentNotifications: NotificationSettingsEntity[];
    @Input() public isOwner: boolean;
    private shipmentSubscriptionService = inject(ShipmentSubscriptionService);
    private readonly fb = inject(FormBuilder);
    public form: FormGroup = this.fb.group({
        shipment_activity_notification: new FormControl({value: false, disabled: true}),
        shipment_status_notification: new FormControl({value: false, disabled: true}),
    });
    public is_customer_user_subscribed = false;
    private shipmentSubscription: Subscription;

    ngOnInit() {
        this.resetForm();
    }

    ngOnDestroy() {
        if (this.shipmentSubscription) {
            this.shipmentSubscription.unsubscribe();
        }
    }

    ngAfterContentChecked() {
        if (this.shipmentActivityNotificationEnabled) {
            this.form.get('shipment_activity_notification')?.enable();
        } else {
            this.form.get('shipment_activity_notification')?.disable();
        }

        if (this.shipmentStatusNotificationEnabled) {
            this.form.get('shipment_status_notification')?.enable();
        } else {
            this.form.get('shipment_status_notification')?.disable();
        }
    }

    resetForm() {
        if (this.shipment.subscription !== null) {
            this.is_customer_user_subscribed =
                this.shipment.subscription.shipment_activity_notification ||
                this.shipment.subscription.shipment_status_notification;
            this.form.patchValue({ ...this.shipment.subscription });
        } else {
            this.form.reset();
        }
    }

    submit() {
        this.shipmentSubscription = this.shipmentSubscriptionService
            .upsertShipmentSubscription(this.shipment.id, this.form.value)
            .subscribe(response => {
                this.shipment.subscription = response;
                this.is_customer_user_subscribed =
                    response.shipment_activity_notification ||
                    response.shipment_status_notification;
                this.form.patchValue({ response });
            });
    }

    get shipmentStatusNotificationEnabled() {
        return this.shipmentNotifications.some(
            notification =>
                notification.name === 'Shipment: Status update' &&
                notification.enabled
        );
    }

    get shipmentActivityNotificationEnabled() {
        return this.shipmentNotifications.some(
            notification =>
                notification.name === 'Shipment: Activity notification' &&
                notification.enabled
        );
    }
}
