import { Injectable } from '@angular/core';
import { EnvironmentConfig } from '../environmentConfig';
import { HttpClient } from '@angular/common/http';
import { Hub } from '@qwyk/models';
import { map, take, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MagayaConnectionService {
    public magayaConnectionStatus: Hub.MagayaConnectionStatus;

    constructor(private env: EnvironmentConfig, private http: HttpClient) {}

    public getMagayaConnectionStatus(): Observable<Hub.MagayaConnectionStatus> {
        if (this.magayaConnectionStatus) {
            return of(this.magayaConnectionStatus);
        }
        return this.http
            .get<{ data: Hub.MagayaConnectionStatus }>(
                `${this.env.backendServer}/api/hub/organization/magaya-connection-status`
            )
            .pipe(
                take(1),
                map(d => d.data),
                tap(c => (this.magayaConnectionStatus = c))
            );
    }
}
